import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/lieky/olynth_ha_05/" className="sel">
              {" "}
              &gt; OLYNTH<sup>®</sup> PLUS <br />1 mg/50 mg/ml{" "}
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> PLUS <br />1 mg/50 mg/ml{" "}<br/>nosová roztoková aerodisperzia
                    </p>
                   {/*} <div className="desc">
                    nosová roztoková aerodisperzia
    </div>*/}
                  </div>
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/olynth_adult.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <div>
                        <strong>
                        Uvoľňuje upchatý nos, urýchľuje hojenie raniek na nosovej sliznici a chráni ju pred podráždením.
                        </strong>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      Počas nádchy je nosová sliznica vystavená negatívnym zmenám, ktoré spôsobujú, 
                      že prostredie v nosovej dutine nie je dostatočne vlhké a je citlivejšie na vplyv 
                      vonkajších faktorov. Kvôli tomu môžu vznikať na nosovej sliznici malé ranky. 
                      OLYNTH<sup>®</sup> PLUS 1 mg/50 mg/ml nosová roztoková aerodisperzia obsahuje liečivá 
                      xylometazolín a dexpantenol a zároveň je bez obsahu konzervačných látok. 
                      Xylometazolín rýchlo uvoľní upchatý nos, zatiaľ čo dexpantenol má upokojujúce účinky 
                      a podporuje hojenie raniek, ktoré sa môžu objaviť na nosovej sliznici počas nádchy.
                      <br />
                      <br />
                      <strong>Olynth<sup>®</sup> Plus prináša 4 benefity v 1:</strong>
                    </div>
                    <ul>
                      <li>Uvoľňuje upchatý nos</li>
                      <li>Urýchľuje hojenie poranenej nosovej sliznice</li>
                      <li>Chráni nosovú sliznicu</li>
                      <li>Neobsahuje konzervačné látky</li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Dávkovanie a spôsob podávania</strong><br />
                      Odporúčaná dávka pre dospelých je jeden vstrek do každej nosovej dierky až 3-krát denne podľa potreby, pokiaľ nie je vaším lekárom uvedené inak.
Tento liek nepoužívajte dlhšie ako 7 dní. Ak sa po 7 dňoch liečby nebudete cítiť lepšie alebo sa budete cítiť horšie, porozprávajte sa so svojím lekárom. S ďalším
 použitím lieku sa môže začať len po niekoľkodňovej prestávke.

                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Upozornenie</strong>
                    </div>
                    <div>
                      <span>Tento liek nepoužívajte dlhšie ako 7 dní. Ak sa po 7 dňoch liečby nebudete cítiť 
                      lepšie alebo sa budete cítiť horšie, porozprávajte sa so svojím lekárom. S ďalším použitím 
                      lieku sa môže začať len po niekoľkodňovej prestávke.
                      </span>
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                  </div>
                  <p>&nbsp;</p>
                  <p><br />
                  <a class="btn_link" href="https://www.sukl.sk/hlavna-stranka/slovenska-verzia/pomocne-stranky/detail-lieku?page_id=386&lie_id=6797C
" target="_blank">PIL - Písomná informácia pre používateľa (link na oficiálne webové stránky ŠÚKL)
                  </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/nadcha/ako_si_spravne_cistit_nos/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/jak_prawidlowo_oczyszczac_nos.png)"}} />
                    </div>
                    <div className="c2">
                      Ako si správne čistiť nos pri nádche?{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/spoznajte_typy_nadchy/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)"}} />
                    </div>
                    <div className="c2">Spoznajte typy nádchy </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/lieky/olynth_ha_05/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
